exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-arts-js": () => import("./../../../src/pages/arts.js" /* webpackChunkName: "component---src-pages-arts-js" */),
  "component---src-pages-commissions-info-js": () => import("./../../../src/pages/commissions-info.js" /* webpackChunkName: "component---src-pages-commissions-info-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-art-category-js": () => import("./../../../src/templates/art-category.js" /* webpackChunkName: "component---src-templates-art-category-js" */)
}

